import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Palette,
  Theme,
} from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { fontfamily } from '../palette';

type MuiTypographyComponentProps = {
  defaultProps?: ComponentsProps['MuiTypography'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTypography'];
  variants?: ComponentsVariants['MuiTypography'];
};

export const muiTypographyComponent: MuiTypographyComponentProps = {
  defaultProps: {
    variantMapping: {
      'desktop-h1': 'h1',
      'mobile-h1': 'h1',
      'desktop-h3': 'h3',
      'mobile-h3': 'h3',
    },
  },
  styleOverrides: {
    root: {
      display: 'block',
      wordBreak: 'break-word',
    },
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'desktop-h1': React.CSSProperties;
    'mobile-h1': React.CSSProperties;
    'desktop-h3': React.CSSProperties;
    'mobile-h3': React.CSSProperties;
    'desktop-button': React.CSSProperties;
    'mobile-button': React.CSSProperties;
    'desktop-p': React.CSSProperties;
    'mobile-p': React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    'desktop-h1'?: React.CSSProperties;
    'mobile-h1'?: React.CSSProperties;
    'desktop-h3'?: React.CSSProperties;
    'mobile-h3'?: React.CSSProperties;
    'desktop-button'?: React.CSSProperties;
    'mobile-button'?: React.CSSProperties;
    'desktop-p'?: React.CSSProperties;
    'mobile-p'?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'desktop-h1': true;
    'mobile-h1': true;
    'desktop-h3': true;
    'mobile-h3': true;
    'desktop-button': true;
    'mobile-button': true;
    'desktop-p': true;
    'mobile-p': true;
  }
}

export const muiTypographyOptions = (palette: Palette): TypographyOptions => {
  return {
    'fontSize': 16,
    'fontFamily': 'serif', // DO NOT REMOVE. This is to ensure the usage of variants for typography.
    'desktop-h1': {
      color: palette.blue.blue_1,
      fontFamily: fontfamily.circular_tt,
      fontSize: '1.75rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '2.625rem',
    },
    'mobile-h1': {
      color: palette.blue.blue_1,
      fontFamily: fontfamily.circular_tt,
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '2.25rem',
    },
    'desktop-h3': {
      color: palette.blue.blue_1,
      fontFamily: fontfamily.circular_tt,
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '1.875rem',
    },
    'mobile-h3': {
      color: palette.blue.blue_1,
      fontFamily: fontfamily.circular_tt,
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '1.5rem',
    },
    'desktop-button': {
      fontFamily: fontfamily.circular_tt,
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '1.875rem',
    },
    'mobile-button': {
      fontFamily: fontfamily.circular_tt,
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '1.5rem',
    },
    'desktop-p': {
      color: palette.blue.blue_1,
      fontFamily: fontfamily.circular_tt,
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 450,
      lineHeight: '1.875rem',
    },
    'mobile-p': {
      color: palette.blue.blue_1,
      fontFamily: fontfamily.circular_tt,
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 450,
      lineHeight: '1.5rem',
    },
  };
};
