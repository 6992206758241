import { VERTICAL } from '@dentolo/common-utils';

const de = {
  'data_change_confirmation.title': 'Ihre Datensicherheit ist uns wichtig!',
  'data_change_confirmation.description':
    'Entweder haben Sie uns um eine Änderung Ihrer Daten gebeten oder wir haben einen möglichen Rechtschreibfehler entdeckt. Bitte überprüfen Sie die Änderung.',
  'data_change_confirmation.proposed_change.label': 'Die vorgeschlagene Änderung lautet:',
  'data_change_confirmation.current_data.label': 'Ihre jetzigen Daten lauten:',
  'data_change_confirmation.confirm_button': 'Änderung bestätigen',
  'data_change_confirmation.decline_button': 'Änderung ablehnen',

  'data_change_confirmation.success.title': 'Ihre Daten wurden aktualisiert!',

  [`${VERTICAL.DENTOLO}.data_change_confirmation.success.description`]:
    'Sie können sich in Ihrem Kundenkonto jederzeit einloggen und Ihre aktuellen Daten einsehen. Klicken Sie [hier](https://account.dentolo.de/), um sich einzuloggen und Ihre Daten direkt zu verwalten.',
  [`${VERTICAL.PETOLO}.data_change_confirmation.success.description`]:
    'Sie können sich in Ihrem Kundenkonto jederzeit einloggen und Ihre aktuellen Daten einsehen. Klicken Sie [hier](https://account.petolo.de/), um sich einzuloggen und Ihre Daten direkt zu verwalten.',
  [`${VERTICAL.VITOLO}.data_change_confirmation.success.description`]:
    'Sie können sich in Ihrem Kundenkonto jederzeit einloggen und Ihre aktuellen Daten einsehen. Klicken Sie [hier](https://account.vitolo.de/), um sich einzuloggen und Ihre Daten direkt zu verwalten.',

  'data_change_confirmation.success.updated_data.label': 'Ihre aktuellen Daten lauten:',

  [`${VERTICAL.DENTOLO}.data_change_confirmation.success.footer_text`]:
    'Wir sind auch telefonisch für Sie da! Sie erreichen uns von Montag bis Freitag, jeweils von 09:00 bis 18:00 Uhr unter 030-959-993300.',
  [`${VERTICAL.PETOLO}.data_change_confirmation.success.footer_text`]:
    'Wir sind auch telefonisch für Sie da! Sie erreichen uns von Montag bis Freitag, jeweils von 09:00 bis 18:00 Uhr unter 030-8145862-90.',
  [`${VERTICAL.VITOLO}.data_change_confirmation.success.footer_text`]:
    'Wir sind auch telefonisch für Sie da! Sie erreichen uns von Montag bis Freitag, jeweils von 09:00 bis 18:00 Uhr unter 030-417-35010.',

  [`${VERTICAL.DENTOLO}.data_change_confirmation.success.footer_signature`]: 'Ihr dentolo Team',
  [`${VERTICAL.PETOLO}.data_change_confirmation.success.footer_signature`]: 'Ihr petolo Team',
  [`${VERTICAL.VITOLO}.data_change_confirmation.success.footer_signature`]: 'Ihr vitolo Team',

  'data_change_confirmation.decline.title': 'Alles Klar! Ihre Daten werden nicht geändert.',
  'data_change_confirmation.decline.description':
    'Sie können sich in Ihrem Kundenkonto jederzeit einloggen und Ihre aktuellen Daten einsehen. Klicken Sie [hier](https://account.dentolo.de/), um sich einzuloggen und Ihre Daten direkt zu verwalten.',
  'data_change_confirmation.decline.existing_data.label': 'Ihre Daten bleiben bei:',
  'data_change_confirmation.decline.footer_text':
    'Wir sind auch telefonisch für Sie da! Sie erreichen uns von Montag bis Freitag, jeweils von 09:00 bis 18:00 Uhr unter 030-959-993300. ',
  'data_change_confirmation.decline.footer_signature': 'Ihr dentolo Team',
};

export default de;
