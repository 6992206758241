import React from 'react';
import ReactDOM from 'react-dom/client';

import { VITE_ROOT_DIV_DATA_CHANGE_CONFIRMATION } from './app/environment';
import DataChangeConfirmation from './main';

const rootElement = document.getElementById(VITE_ROOT_DIV_DATA_CHANGE_CONFIRMATION);

if (rootElement) {
  if (!rootElement.shadowRoot) {
    rootElement.attachShadow({ mode: 'open' });
  }

  const root = ReactDOM.createRoot(rootElement.shadowRoot || rootElement);

  root.render(<DataChangeConfirmation />);
}
