import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
  buttonClasses,
  svgIconClasses,
} from '@mui/material';

type MuiButtonComponentProps = {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
};

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    icon: true;
    dropdownIcon: true;
    danger: true;
  }
}

export const muiButtonComponent: MuiButtonComponentProps = {
  defaultProps: {
    disableRipple: true,
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      'background': theme.palette.primary.main,
      'borderRadius': '7px',
      'boxShadow': 'none',
      'color': theme.palette.primary.contrastText,
      'padding': '0.5rem 1rem',
      'textTransform': 'none',
      'transition': 'none',

      [`&:hover`]: {
        background: theme.palette.primary.main,
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.grey['50']}, ${theme.palette.grey['50']})`,
        boxShadow: 'none',
      },

      [`&:focus`]: {
        boxShadow: theme.shadows[1],
      },

      '&.Mui-disabled': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        opacity: '0.6',
      },
    }),
    outlined: ({ theme }) => ({
      'background': 'transparent',
      'color': theme.palette.text.primary,
      'border': `2px solid ${theme.palette.text.primary}`,

      [`&:hover`]: {
        background: theme.palette.common.white,
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.grey['100']}, ${theme.palette.grey['100']})`,
        border: `2px solid ${theme.palette.text.primary}`,
      },

      '&.Mui-disabled': {
        background: theme.palette.common.white,
        border: `2px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary,
      },
    }),
    text: ({ theme }) => ({
      background: theme.palette.neutral.white,
      border: 'none',
      color: theme.palette.neutral.black,
      padding: '0.25rem',
      textTransform: 'none',

      [`&:hover, &:active`]: {
        background: theme.palette.neutral.white,
        opacity: '0.8',
      },
    }),
  },
  variants: [
    {
      props: { variant: 'icon' },
      style: ({ theme }) => ({
        'background': 'transparent',
        'border': 'none',
        'borderRadius': '50%',
        'boxShadow': 'none',
        'color': theme.palette.blue.blue_1,
        'margin': 0,
        'minWidth': 'auto',
        'padding': 0,

        [`&:hover`]: {
          background: 'transparent',
        },

        [`&:active`]: {
          background: theme.palette.neutral.white,
          color: theme.palette.blue.blue_1,
        },

        '&.Mui-disabled': {
          background: 'transparent',
          color: theme.palette.blue.blue_1,
        },
      }),
    },
    {
      props: { variant: 'dropdownIcon' },
      style: ({ theme }) => ({
        'background': 'transparent',
        'border': 'none',
        'borderRadius': '2px',
        'color': theme.palette.blue.blue_1,
        'height': 'auto',
        'minWidth': 'auto',
        'padding': 0,

        [`&:hover`]: {
          background: 'transparent',
        },

        [`&:active`]: {
          background: theme.palette.neutral.white,
          color: theme.palette.blue.blue_1,
        },

        [`.${buttonClasses.endIcon}`]: {
          color: theme.palette.blue.blue_1,
          marginLeft: 0,

          [`.${svgIconClasses.root}`]: {
            fontSize: '1rem',
          },
        },

        '&.Mui-disabled': {
          background: 'transparent',
          color: theme.palette.gray.gray_1,

          [`.${buttonClasses.endIcon}`]: {
            color: theme.palette.gray.gray_1,
          },
        },
      }),
    },
    {
      props: { variant: 'dropdownIcon', color: 'info' },
      style: ({ theme }) => ({
        color: theme.palette.blue.blue_3,

        [`&:active`]: {
          color: theme.palette.blue.blue_3,
        },

        [`.${buttonClasses.endIcon}`]: {
          color: theme.palette.blue.blue_3,
        },
      }),
    },
    {
      props: { variant: 'danger' },
      style: ({ theme }) => ({
        'background': theme.palette.red.red_1,
        'borderColor': theme.palette.red.red_1,
        'color': theme.palette.neutral.white,

        [`&:hover, &:active`]: {
          background: theme.palette.red.red_2,
          borderColor: theme.palette.red.red_1,
        },

        '&.Mui-disabled': {
          background: theme.palette.red.red_1,
          color: theme.palette.neutral.white,
        },
      }),
    },
  ],
};
