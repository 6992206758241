import { useState } from 'react';
import { VITE_API_BASE_URL, VITE_API_KEY } from '../app/environment';
import { fetchWithErrorHandling, jsonHeaders, SuccessResponse } from '../utils/utils';

const baseUrl = VITE_API_BASE_URL;
const apiKey = VITE_API_KEY;

export enum CUSTOMER_DATA_UPDATE_REQUEST_STATUS {
  CONFIRMED = 'confirmed',
  DENIED = 'denied',
}

export const confirmCustomerDataUpdateRequest = (
  requestId: string,
  status: CUSTOMER_DATA_UPDATE_REQUEST_STATUS
) => {
  const urlToUse = `${baseUrl}/api/v1/customer_update_requests/${requestId || ''}`;

  return fetchWithErrorHandling<SuccessResponse>(urlToUse, {
    headers: jsonHeaders(apiKey),
    method: 'PUT',
    body: JSON.stringify({ request_status: status }),
  });
};

export default function useConfirmCustomerDataUpdateRequest(
  requestId: string,
  setActionSuccess: (completed: boolean) => void
) {
  const [confirming, setConfirming] = useState(false);

  const triggerConfirmCustomerDataUpdateRequest = (status: CUSTOMER_DATA_UPDATE_REQUEST_STATUS) => {
    if (requestId) {
      setConfirming(true);

      confirmCustomerDataUpdateRequest(requestId, status)
        .then((response) => {
          if (response && 'status' in response && response.status === 'success') {
            setActionSuccess(true);
          }
        })
        .finally(() => setConfirming(false));
    }
  };

  return { triggerConfirmCustomerDataUpdateRequest, confirming };
}
