import { createTheme } from '@mui/material';
import defaultTheme from './default.theme';
import { boxShadow, colors } from './palette';

const vitoloTheme = createTheme(defaultTheme, {
  palette: {
    primary: {
      main: colors.violet_2,
      contrastText: colors.blue_1,
    },
    grey: {
      '50': colors.gradient_2,
      '100': colors.gradient_2,
    },
  },
  shadows: [
    'none',
    boxShadow.focus_shadow_violet_3,
    boxShadow.navigation_shadow,
    boxShadow.dropdown_shadow,
    boxShadow.header_shadow,
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
});

export default vitoloTheme;
