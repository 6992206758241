export function safeParseJSON<T>(json: string): T | null {
  try {
    return JSON.parse(json);
  } catch (error) {
    return null;
  }
}

export const jsonHeaders = (apiKey: string) => {
  return {
    'Content-type': 'application/json',
    'X-API-KEY': apiKey,
    'KeyId': apiKey,
  };
};

export interface SuccessResponse {
  status: 'success';
}

export interface FailureResponse {
  status: 'failure' | 'fail';
  error?: string | null;
}

export type APIResponse<T> = Promise<T | FailureResponse | null>;

const processAPIError = (
  url: string,
  options: RequestInit,
  response: Response
): Promise<FailureResponse> => {
  return response
    .text()
    .then((responseBody) => {
      const consoleError = JSON.stringify({
        url,
        options,
        status: `${response.status} ${response.statusText}`,
        response: responseBody,
      });
      console.error('[FETCH RESPONSE]', consoleError);

      const errorResponse: FailureResponse = {
        status: 'failure',
        error: responseBody,
      };
      return errorResponse;
    })
    .catch((error) => {
      console.error('[FETCH RESPONSE ERROR]', url, options, error);

      const errorResponse: FailureResponse = {
        status: 'failure',
      };
      return errorResponse;
    });
};

async function handleAPIResponseError<T>(url: string, options: RequestInit, response: Response) {
  if (response.ok) {
    const responseBody = await response.text();
    if (responseBody) {
      return safeParseJSON<T>(responseBody);
    }
    return null;
  }
  const errorResponse = await processAPIError(url, options, response);
  return errorResponse;
}

export function fetchWithErrorHandling<T>(url: string, options: RequestInit): APIResponse<T> {
  const request = new Request(url, options);
  return fetch(request)
    .then((response) => handleAPIResponseError<T>(url, options, response))
    .catch((error) => {
      console.error('[FETCH ERROR]', url, options, error);

      const errorResponse: FailureResponse = {
        status: 'failure',
      };
      return errorResponse;
    });
}
