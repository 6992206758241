import React from 'react';

import { useVertical, VERTICAL } from '@dentolo/common-utils';

import HeaderLogoVitolo from './HeaderLogoVitolo';
import HeaderLogoDADirekt from './HeaderLogoDADirekt';
import HeaderLogoDentolo from './HeaderLogoDentolo';
import HeaderLogoPetolo from './HeaderLogoPetolo';

import { StyledHeaderLogoLinks } from './ApplicationHeader.styled';

export default function HeaderLogo() {
  const vertical = useVertical();

  const showDaDirektLogo = false;

  return (
    <StyledHeaderLogoLinks>
      {vertical === VERTICAL.DENTOLO && <HeaderLogoDentolo />}
      {vertical === VERTICAL.PETOLO && <HeaderLogoPetolo />}
      {vertical === VERTICAL.VITOLO && <HeaderLogoVitolo />}

      {showDaDirektLogo && <HeaderLogoDADirekt />}
    </StyledHeaderLogoLinks>
  );
}
