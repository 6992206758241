import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useVertical } from '@dentolo/common-utils';

import { CustomerDataUpdateRequest } from '../../hooks/useCustomerDataUpdateRequest';
import DataChangeConfirmationSuccessIconVitolo from './DataChangeConfirmationSuccessIconVitolo';

import {
  StyledChangedData,
  StyledDataChangeConfirmationPageContainer,
  StyledDescriptionContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledUpdatedDataSection,
  StyledUpdatedDataSectionTitle,
  StyledFooterTextContainer,
} from '../DataChangeConfirmationPage/DataChangeConfirmationPage.styled';

interface DataChangeConfirmationSuccessPageProps {
  dataUpdateRequests: CustomerDataUpdateRequest[];
  requestLoading: boolean;
}

export default function DataChangeConfirmationSuccessPage(
  props: DataChangeConfirmationSuccessPageProps
) {
  const { dataUpdateRequests, requestLoading } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const vertical = useVertical();

  return (
    <Container>
      <StyledDataChangeConfirmationPageContainer>
        <StyledIconContainer>
          <DataChangeConfirmationSuccessIconVitolo />
        </StyledIconContainer>

        <StyledTitleContainer>
          <Typography variant={isMobile ? 'mobile-h1' : 'desktop-h1'}>
            <FormattedMessage id="data_change_confirmation.success.title" />
          </Typography>
        </StyledTitleContainer>

        <StyledDescriptionContainer>
          <Typography variant={isMobile ? 'mobile-p' : 'desktop-p'}>
            <FormattedMessage id={`${vertical}.data_change_confirmation.success.description`} />
          </Typography>
        </StyledDescriptionContainer>

        {!requestLoading && (
          <StyledUpdatedDataSection>
            <StyledUpdatedDataSectionTitle>
              <Typography variant={isMobile ? 'mobile-p' : 'desktop-p'}>
                <FormattedMessage id="data_change_confirmation.success.updated_data.label" />
              </Typography>
            </StyledUpdatedDataSectionTitle>

            <div>
              {Array.isArray(dataUpdateRequests) &&
                dataUpdateRequests.map((request) => (
                  <StyledChangedData key={request?.in}>
                    <Typography variant={isMobile ? 'mobile-h3' : 'desktop-h3'}>
                      {request.requested_changes?.old_value}
                    </Typography>
                  </StyledChangedData>
                ))}
            </div>
          </StyledUpdatedDataSection>
        )}

        <StyledFooterTextContainer>
          <Typography variant={isMobile ? 'mobile-p' : 'desktop-p'}>
            <FormattedMessage id={`${vertical}.data_change_confirmation.success.footer_text`} />
          </Typography>
        </StyledFooterTextContainer>

        <StyledFooterTextContainer>
          <Typography variant={isMobile ? 'mobile-p' : 'desktop-p'}>
            <FormattedMessage
              id={`${vertical}.data_change_confirmation.success.footer_signature`}
            />
          </Typography>
        </StyledFooterTextContainer>
      </StyledDataChangeConfirmationPageContainer>
    </Container>
  );
}
