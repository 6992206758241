import { styled } from '@mui/material';

export const StyledDataChangeConfirmationPageContainer = styled('div')(() => ({
  margin: '0 auto',
  maxWidth: '42rem',
  padding: '0 1.25rem',
  textAlign: 'center',
}));

export const StyledIconContainer = styled('div')(({ theme }) => ({
  marginBottom: '1.5rem',

  [`svg`]: {
    height: '4rem',
    width: '100%',
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: '1rem',

    [`svg`]: {
      height: '4.5rem',
      width: '100%',
    },
  },
}));

export const StyledTitleContainer = styled('div')(() => ({
  marginBottom: '1rem',
}));

export const StyledDescriptionContainer = styled('div')(({ theme }) => ({
  marginBottom: '1rem',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '2rem',
  },
}));

export const StyledDataSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1rem',

  [theme.breakpoints.up('sm')]: {
    columnGap: '1rem',
    flexDirection: 'row',
    marginBottom: '1.5rem',
  },
}));

export const StyledDataSectionTitle = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  textAlign: 'left',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    textAlign: 'right',
    width: '50%',
  },
}));

export const StyledUpdatedDataSection = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '4rem',
}));

export const StyledUpdatedDataSectionTitle = styled('div')(() => ({
  textAlign: 'center',
  width: '100%',
}));

export const StyledChangedData = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.gray.gray_3,
  height: 'fit-content',
  margin: '0.25rem 0',
  padding: '0.5rem',

  [`&:first-of-type`]: {
    marginTop: '0',
  },

  [`&:last-of-type`]: {
    marginBottom: '0',
  },
}));

export const StyledButtonContainer = styled('div')(({ theme }) => ({
  columnGap: '1rem',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1rem',
  rowGap: '1rem',
  width: '100%',

  button: {
    flexGrow: 1,
  },

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    marginBottom: '2rem',
  },
}));

export const StyledFooterTextContainer = styled('div')(({ theme }) => ({
  marginBottom: '1rem',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '1.5rem',
  },
}));
