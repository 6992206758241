import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';

import { CustomerDataUpdateRequest } from '../../hooks/useCustomerDataUpdateRequest';
import useConfirmCustomerDataUpdateRequest, {
  CUSTOMER_DATA_UPDATE_REQUEST_STATUS,
} from '../../hooks/useConfirmCustomerDataUpdateRequest';
import DataChangeConfirmationIconVitolo from './DataChangeConfirmationIconVitolo';

import {
  StyledDataChangeConfirmationPageContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledDescriptionContainer,
  StyledButtonContainer,
  StyledDataSection,
  StyledDataSectionTitle,
  StyledChangedData,
} from './DataChangeConfirmationPage.styled';

interface DataChangeConfirmationPageProps {
  requestId: string;
  dataUpdateRequests: CustomerDataUpdateRequest[];
  requestLoading: boolean;
  setPerformedAction: (action: CUSTOMER_DATA_UPDATE_REQUEST_STATUS | null) => void;
  setActionSuccess: (completed: boolean) => void;
}

export default function DataChangeConfirmationPage(props: DataChangeConfirmationPageProps) {
  const { requestId, dataUpdateRequests, requestLoading, setPerformedAction, setActionSuccess } =
    props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { triggerConfirmCustomerDataUpdateRequest, confirming } =
    useConfirmCustomerDataUpdateRequest(requestId || '', setActionSuccess);

  const handleConfirm = () => {
    triggerConfirmCustomerDataUpdateRequest(CUSTOMER_DATA_UPDATE_REQUEST_STATUS.CONFIRMED);
    setPerformedAction(CUSTOMER_DATA_UPDATE_REQUEST_STATUS.CONFIRMED);
  };

  const handleDeny = () => {
    triggerConfirmCustomerDataUpdateRequest(CUSTOMER_DATA_UPDATE_REQUEST_STATUS.DENIED);
    setPerformedAction(CUSTOMER_DATA_UPDATE_REQUEST_STATUS.DENIED);
  };

  return (
    <Container>
      <StyledDataChangeConfirmationPageContainer>
        <StyledIconContainer>
          <DataChangeConfirmationIconVitolo />
        </StyledIconContainer>

        <StyledTitleContainer>
          <Typography variant={isMobile ? 'mobile-h1' : 'desktop-h1'}>
            <FormattedMessage id="data_change_confirmation.title" />
          </Typography>
        </StyledTitleContainer>

        <StyledDescriptionContainer>
          <Typography variant={isMobile ? 'mobile-p' : 'desktop-p'}>
            <FormattedMessage id="data_change_confirmation.description" />
          </Typography>
        </StyledDescriptionContainer>

        {!requestLoading && (
          <>
            <StyledDataSection>
              <StyledDataSectionTitle>
                <Typography variant={isMobile ? 'mobile-p' : 'desktop-p'}>
                  <FormattedMessage id="data_change_confirmation.current_data.label" />
                </Typography>
              </StyledDataSectionTitle>

              <div>
                {Array.isArray(dataUpdateRequests) &&
                  dataUpdateRequests.map((request) => (
                    <StyledChangedData key={request?.in}>
                      <Typography variant={isMobile ? 'mobile-h3' : 'desktop-h3'}>
                        {request.requested_changes?.old_value}
                      </Typography>
                    </StyledChangedData>
                  ))}
              </div>
            </StyledDataSection>

            <StyledDataSection>
              <StyledDataSectionTitle>
                <Typography variant={isMobile ? 'mobile-p' : 'desktop-p'}>
                  <FormattedMessage id="data_change_confirmation.proposed_change.label" />
                </Typography>
              </StyledDataSectionTitle>

              <div>
                {Array.isArray(dataUpdateRequests) &&
                  dataUpdateRequests.map((request) => (
                    <StyledChangedData key={request?.in}>
                      <Typography variant={isMobile ? 'mobile-h3' : 'desktop-h3'}>
                        {request.requested_changes?.updated_value}
                      </Typography>
                    </StyledChangedData>
                  ))}
              </div>
            </StyledDataSection>

            <StyledButtonContainer>
              <Button disabled={confirming} onClick={handleConfirm}>
                <Typography variant={isMobile ? 'mobile-button' : 'desktop-button'}>
                  <FormattedMessage id="data_change_confirmation.confirm_button" />
                </Typography>
              </Button>

              <Button variant="outlined" disabled={confirming} onClick={handleDeny}>
                <Typography variant={isMobile ? 'mobile-button' : 'desktop-button'}>
                  <FormattedMessage id="data_change_confirmation.decline_button" />
                </Typography>
              </Button>
            </StyledButtonContainer>
          </>
        )}
      </StyledDataChangeConfirmationPageContainer>
    </Container>
  );
}
