import { VERTICAL } from '../constants';

export default function useVertical() {
  if (window?.location?.hostname?.includes('dentolo')) {
    return VERTICAL.DENTOLO;
  }
  if (window?.location?.hostname?.includes('petolo')) {
    return VERTICAL.PETOLO;
  }
  if (window?.location?.hostname?.includes('vitolo')) {
    return VERTICAL.VITOLO;
  }
  return VERTICAL.DENTOLO;
}
