import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';

import useCustomerDataUpdateRequest from '../hooks/useCustomerDataUpdateRequest';
import { ApplicationHeader } from '../components/ApplicationHeader';
import DataChangeConfirmationPage from '../pages/DataChangeConfirmationPage';
import DataChangeConfirmationSuccessPage from '../pages/DataChangeConfirmationSuccessPage';
import DataChangeConfirmationDeclinePage from '../pages/DataChangeConfirmationDeclinePage';

import { StyledAppContainer } from './App.styled';
import { CUSTOMER_DATA_UPDATE_REQUEST_STATUS } from '../hooks/useConfirmCustomerDataUpdateRequest';

export default function App() {
  const { search } = useLocation();
  const { request_id: requestId } = QueryString.parse(search);
  const sanitizedRequestId = typeof requestId === 'string' ? requestId : '';

  const { requestLoading, dataUpdateRequests } = useCustomerDataUpdateRequest(sanitizedRequestId);

  const [performedAction, setPerformedAction] =
    useState<CUSTOMER_DATA_UPDATE_REQUEST_STATUS | null>(null);
  const [actionSuccess, setActionSuccess] = useState(false);

  return (
    <StyledAppContainer>
      <ApplicationHeader />

      {!requestLoading && dataUpdateRequests.length > 0 && !performedAction && (
        <DataChangeConfirmationPage
          requestId={sanitizedRequestId}
          dataUpdateRequests={dataUpdateRequests}
          requestLoading={requestLoading}
          setPerformedAction={setPerformedAction}
          setActionSuccess={setActionSuccess}
        />
      )}

      {!requestLoading &&
        dataUpdateRequests.length > 0 &&
        actionSuccess &&
        performedAction === CUSTOMER_DATA_UPDATE_REQUEST_STATUS.CONFIRMED && (
          <DataChangeConfirmationSuccessPage
            dataUpdateRequests={dataUpdateRequests}
            requestLoading={requestLoading}
          />
        )}

      {!requestLoading &&
        dataUpdateRequests.length > 0 &&
        actionSuccess &&
        performedAction === CUSTOMER_DATA_UPDATE_REQUEST_STATUS.DENIED && (
          <DataChangeConfirmationDeclinePage
            dataUpdateRequests={dataUpdateRequests}
            requestLoading={requestLoading}
          />
        )}
    </StyledAppContainer>
  );
}
