import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DataChangeConfirmationIconVitolo(props: SvgIconProps) {
  return (
    <SvgIcon inheritViewBox {...props}>
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M67.137 24.6187C67.137 24.6187 67.2101 24.6187 67.2649 24.6187C67.2649 19.4453 67.2649 14.2718 67.2649 9.09831C67.2649 6.98992 66.2412 6.08806 64.1938 6.39275C64.0658 6.41103 63.9195 6.46587 63.7916 6.48415C55.2179 7.14226 46.9732 5.80776 39.5878 1.31068C37.0284 -0.243195 35.6025 -0.480845 32.897 1.16443C25.1276 5.93572 16.4442 7.41647 7.39523 6.30134C5.53058 6.06369 4.98216 6.46587 5.00044 8.36707C5.09184 18.9151 4.9456 29.4814 5.11012 40.0295C5.23809 47.9268 8.4738 54.5079 14.3785 59.7545C20.466 65.1656 27.6504 68.6755 35.0724 71.7467C35.7853 72.0392 36.4435 72.1123 37.2478 71.7833C42.9514 69.3885 48.4905 66.6829 53.6092 63.173C61.1043 58.0178 66.2961 51.2905 67.0273 42.0038C67.4843 36.2453 67.1187 30.432 67.1187 24.637L67.137 24.6187Z"
          fill="white"
        />
        <path
          d="M67.1358 24.6171C67.1358 30.4121 67.5014 36.2254 67.0444 41.9839C66.3132 51.2705 61.1214 57.9979 53.6263 63.1531C48.5077 66.6813 42.9686 69.3686 37.265 71.7634C36.4606 72.0924 35.8025 72.0193 35.0895 71.7268C27.6492 68.6556 20.4649 65.1457 14.3956 59.7346C8.49093 54.488 5.23695 47.9069 5.12726 40.0096C4.96274 29.4615 5.10898 18.8952 5.01758 8.34717C5.01758 6.44596 5.54772 6.04378 7.41237 6.28143C16.4614 7.39656 25.1448 5.91582 32.9141 1.14452C35.6197 -0.519033 37.0456 -0.281383 39.6049 1.29077C46.9904 5.78785 55.2167 7.14063 63.8087 6.46424C63.9367 6.46424 64.0829 6.39112 64.2109 6.37284C66.2584 6.06816 67.2821 6.97001 67.2821 9.0784C67.2821 14.2519 67.2821 19.4253 67.2821 24.5988C67.2455 24.5988 67.209 24.5988 67.1541 24.5988L67.1358 24.6171ZM63.827 26.9022C63.827 21.4728 63.7539 16.994 63.8636 12.5152C63.9001 10.742 63.37 10.1021 61.5236 10.157C53.8457 10.3581 46.3505 9.15152 39.6232 5.38567C36.8811 3.85008 35.1261 4.06945 32.5668 5.47708C25.8577 9.13324 18.4723 10.2849 10.8857 10.157C9.02108 10.1204 8.36297 10.6688 8.38125 12.6249C8.49093 21.2534 8.39953 29.882 8.49094 38.4922C8.56406 46.1154 11.3793 52.4954 17.2475 57.5043C22.5855 62.0745 28.7644 65.1457 35.1078 67.9792C35.7842 68.2717 36.3692 68.29 37.0639 67.9792C42.7126 65.4565 48.3249 62.8423 53.2607 59.0216C59.0374 54.5428 62.9495 49.0037 63.6076 41.472C64.0647 36.2985 63.6625 31.1251 63.8636 26.9022H63.827Z"
          fill="#003264"
        />
        <path
          d="M19.1129 27.7617C19.1129 24.8734 19.186 21.9667 19.0763 19.0784C19.0397 17.7621 19.4785 17.4514 20.7216 17.5062C25.9133 17.689 30.8126 16.5556 35.328 13.9049C35.9495 13.5393 36.4614 13.4844 37.0281 13.8683C41.4155 16.6836 46.3147 17.561 51.3968 17.4879C52.8958 17.4696 53.2066 17.9449 53.1883 19.3526C53.0969 23.9593 52.969 28.6027 53.1883 33.1912C53.5722 40.9239 49.9709 46.1705 43.4812 49.7719C41.7445 50.7407 39.8433 51.4354 38.1798 52.4957C36.5893 53.5012 35.2548 53.1538 33.7741 52.4226C29.5329 50.302 25.2918 48.1997 22.2206 44.3607C20.1914 41.838 19.2226 38.9862 19.1494 35.8053C19.0946 33.118 19.1494 30.4307 19.1494 27.7435C19.1494 27.7435 19.1129 27.7435 19.0946 27.7435L19.1129 27.7617ZM48.3074 32.0029C48.0697 25.5498 43.2984 20.1569 36.0775 20.1204C29.1308 20.0838 23.8659 25.9519 24.1218 33.0998C24.3412 39.2604 30.1911 44.5618 36.5711 44.379C43.1156 44.1962 48.5267 38.4926 48.3074 32.0029Z"
          fill="#F5F2FF"
        />
        <path
          d="M48.3078 32.0216C48.5271 38.5113 43.116 44.2149 36.5715 44.3977C30.1915 44.5805 24.3416 39.2791 24.1222 33.1185C23.8663 25.9707 29.1312 20.1208 36.0779 20.1391C43.2805 20.1756 48.0701 25.5685 48.3078 32.0216ZM30.2463 30.4312C28.8387 30.5043 28.2354 32.0399 29.1495 33.2647C30.2097 34.6906 31.4894 35.9703 32.5131 37.4145C33.6283 39.0049 34.6337 38.8038 35.822 37.579C37.9791 35.3487 40.1362 33.1367 42.3117 30.943C43.0429 30.1935 43.6279 29.3892 42.787 28.4569C41.8729 27.4514 40.9589 27.8719 40.118 28.6945C38.5641 30.2484 36.9554 31.7657 35.4563 33.3744C34.5789 34.3433 33.9573 34.5444 33.1895 33.3013C32.7142 32.5518 32.111 31.8754 31.526 31.2173C31.1603 30.8151 30.7399 30.4495 30.228 30.4312H30.2463Z"
          fill="#CBB7FF"
        />
        <path
          d="M30.2478 30.4135C30.7597 30.4501 31.1984 30.7974 31.5458 31.1996C32.1307 31.876 32.7523 32.5341 33.2093 33.2836C33.9771 34.5267 34.5804 34.3256 35.4761 33.3567C36.9569 31.7297 38.5839 30.2307 40.1377 28.6768C40.9604 27.8542 41.8927 27.4337 42.8067 28.4392C43.6477 29.3715 43.081 30.1941 42.3314 30.9254C40.156 33.1191 37.9806 35.331 35.8417 37.5613C34.6535 38.7861 33.6481 39.0055 32.5329 37.3968C31.5092 35.9343 30.2295 34.6729 29.1692 33.247C28.2552 32.0222 28.8585 30.5049 30.2661 30.4135H30.2478Z"
          fill="#003264"
        />
      </svg>
    </SvgIcon>
  );
}
