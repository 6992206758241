import { styled } from '@mui/material';

export const StyledHeader = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  height: '4rem',
  zIndex: 1000,
}));

export const StyledHeaderSticky = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: '4rem',
  backgroundColor: theme?.palette?.neutral?.white,
  boxShadow: theme?.boxShadow?.header_shadow,
  zIndex: 99,
}));

export const StyledHeaderContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  margin: '0 auto',
  padding: '0 1rem',
  width: '100%',

  [`@media (min-width: 768px)`]: {
    maxWidth: '45rem',
  },

  [`@media (min-width: 992px)`]: {
    maxWidth: '60rem',
  },
}));

export const StyledHeaderLogoLinks = styled('div')(() => ({
  alignItems: 'center',
  columnGap: '2rem',
  display: 'flex',

  svg: {
    width: 'auto',
    height: '2rem',
  },
}));
