import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Typography } from '@mui/material';

import { CustomerDataUpdateRequest } from '../../hooks/useCustomerDataUpdateRequest';

import { StyledDataChangeConfirmationPageContainer } from '../DataChangeConfirmationPage/DataChangeConfirmationPage.styled';

interface DataChangeConfirmationDeclinePageProps {
  dataUpdateRequests: CustomerDataUpdateRequest[];
  requestLoading: boolean;
}

export default function DataChangeConfirmationDeclinePage(
  props: DataChangeConfirmationDeclinePageProps
) {
  const { dataUpdateRequests, requestLoading } = props;

  return (
    <Container>
      <StyledDataChangeConfirmationPageContainer>
        <Typography variant="desktop-h3" color="primary">
          <FormattedMessage id="data_change_confirmation.decline.title" />
        </Typography>

        <Typography variant="h3" color="primary">
          <FormattedMessage id="data_change_confirmation.decline.description" />
        </Typography>

        {!requestLoading && (
          <>
            <br />

            <Typography variant="h3" color="primary">
              <FormattedMessage id="data_change_confirmation.decline.existing_data.label" />
            </Typography>

            {Array.isArray(dataUpdateRequests) &&
              dataUpdateRequests.map((request) => (
                <div key={request?.in}>
                  <Typography variant="desktop-h3" color="primary">
                    {request.requested_changes?.old_value}
                  </Typography>
                </div>
              ))}

            <br />
          </>
        )}

        <Typography variant="h3" color="primary">
          <FormattedMessage id="data_change_confirmation.decline.footer_text" />
        </Typography>

        <Typography variant="h3" color="primary">
          <FormattedMessage id="data_change_confirmation.decline.footer_signature" />
        </Typography>
      </StyledDataChangeConfirmationPageContainer>
    </Container>
  );
}
