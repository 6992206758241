import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

type MuiInputComponentProps = {
  defaultProps?: ComponentsProps['MuiInput'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInput'];
  variants?: ComponentsVariants['MuiInput'];
};

export const muiInputComponent: MuiInputComponentProps = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&::before': {
        borderBottom: `1px solid ${theme.palette.gray.gray_2}`,
      },

      '&::after': {
        borderBottom: `1px solid ${theme.palette.blue.blue_1}`,
      },

      '&:hover:not(.Mui-disabled, .Mui-error)': {
        '&::before': {
          borderBottom: `1px solid ${theme.palette.blue.blue_1}`,
        },
      },

      '&.Mui-disabled::before': {
        borderBottomStyle: 'dashed',
        borderBottomColor: theme.palette.gray.gray_1,
        textFillColor: theme.palette.gray.gray_1,
      },
    }),
  },
};
