import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function HeaderLogoDentolo(props: SvgIconProps) {
  return (
    <SvgIcon inheritViewBox {...props}>
      <svg
        width="113px"
        height="32px"
        viewBox="0 0 113 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Styles-and-Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Styles-Copy-5" transform="translate(-601.000000, -1263.000000)" fill="#003366">
            <g id=".logo.dentolo.small" transform="translate(601.000000, 1263.000000)">
              <g id="Group-3" transform="translate(0.000000, 3.000000)">
                <g id="faircare_logo_S" fillRule="nonzero">
                  <g id="Group">
                    <path
                      d="M36.1,20.8 C35.2,23.7 32.6,26.1 28.5,26.1 C23.9,26.1 19.8,22.8 19.8,17.1 C19.8,11.7 23.8,8.3 28.1,8.3 C33.3,8.3 36.4,11.6 36.4,17 C36.4,17.6 36.3,18.3 36.3,18.4 L24.3,18.4 C24.4,20.6 26.3,22.2 28.5,22.2 C30.6,22.2 31.8,21.1 32.3,19.6 L36.1,20.8 Z M31.9,15.3 C31.8,13.6 30.7,12 28.1,12 C25.7,12 24.5,13.8 24.3,15.3 L31.9,15.3 Z"
                      id="Shape"
                    />
                    <path
                      d="M43.3,25.6 L38.8,25.6 L38.8,8.8 L43.2,8.8 L43.2,10.9 C44.2,9.2 46.2,8.4 48,8.4 C52.2,8.4 54.1,11.4 54.1,15.1 L54.1,25.7 L49.6,25.7 L49.6,15.9 C49.6,14 48.7,12.6 46.5,12.6 C44.5,12.6 43.4,14.1 43.4,16.1 L43.4,25.6 L43.3,25.6 Z"
                      id="Shape"
                    />
                    <path
                      d="M63.2,8.8 L66.6,8.8 L66.6,12.8 L63.2,12.8 L63.2,19.8 C63.2,21.3 63.9,21.7 65.2,21.7 C65.7,21.7 66.4,21.6 66.6,21.6 L66.6,25.4 C66.2,25.6 65.4,25.8 64,25.8 C60.7,25.8 58.7,23.9 58.7,20.6 L58.7,12.8 L55.7,12.8 L55.7,8.8 L56.6,8.8 C58.4,8.8 59.2,7.6 59.2,6.1 L59.2,3.7 L63.3,3.7 L63.3,8.8 L63.2,8.8 Z"
                      id="Shape"
                    />
                    <path
                      d="M85.4,17.2 C85.4,22.3 81.6,26.1 76.6,26.1 C71.6,26.1 67.8,22.3 67.8,17.2 C67.8,12 71.6,8.3 76.6,8.3 C81.7,8.3 85.4,12 85.4,17.2 Z M80.9,17.2 C80.9,14 78.9,12.4 76.6,12.4 C74.4,12.4 72.3,14 72.3,17.2 C72.3,20.3 74.3,22 76.6,22 C78.9,22 80.9,20.4 80.9,17.2 Z"
                      id="Shape"
                    />
                    <polygon id="Shape" points="87.9 25.6 87.9 0.9 92.4 0.9 92.4 25.6" />
                    <path
                      d="M112.5,17.2 C112.5,22.3 108.7,26.1 103.7,26.1 C98.7,26.1 94.9,22.3 94.9,17.2 C94.9,12 98.7,8.3 103.7,8.3 C108.7,8.3 112.5,12 112.5,17.2 Z M108,17.2 C108,14 106,12.4 103.7,12.4 C101.5,12.4 99.4,14 99.4,17.2 C99.4,20.3 101.4,22 103.7,22 C105.9,22 108,20.4 108,17.2 Z"
                      id="Shape"
                    />
                    <path
                      d="M17.3,22.5 L17.3,0.9 L12.8,0.9 L12.8,10.5 C12.3,9.6 11,8.4 8.1,8.4 C3.4,8.4 0.1,12.3 0.1,17.2 C0.1,22.3 3.5,26 8.3,26 C10.6,26 12.3,25 13,23.6 C13,24.4 13.1,25.2 13.2,25.5 L17.5,25.5 C17.3,24.9 17.3,23.7 17.3,22.5 Z M12,19.8 C11.2,21.4 9.7,22.1 8.1,22.1 C6.1,22 4.7,20.8 4.2,18.7 C4.1,18.2 4.1,17.7 4.1,17.2 L12.5,17.2 C12.5,18.1 12.4,19 12,19.8 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
