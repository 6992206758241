import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function HeaderLogoVitolo(props: SvgIconProps) {
  return (
    <SvgIcon inheritViewBox {...props}>
      <svg
        width="93"
        height="29"
        viewBox="0 0 93 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.4046 3.62566C28.4046 5.26374 27.0767 6.59166 25.4387 6.59166C23.8006 6.59166 22.4727 5.26374 22.4727 3.62566C22.4727 1.98759 23.8006 0.659668 25.4387 0.659668C27.0767 0.659668 28.4046 1.98759 28.4046 3.62566Z"
          fill="#003264"
        />
        <path
          d="M83.1337 8.23425C84.5361 8.23425 85.8345 8.49055 87.0325 9.00488C88.2565 9.49131 89.3004 10.1835 90.1695 11.0831C91.0611 11.9549 91.7502 13.0079 92.2332 14.2423C92.7439 15.475 92.9984 16.8227 92.9984 18.2855C92.9984 19.7483 92.7439 21.096 92.2332 22.3304C91.7502 23.5369 91.0629 24.59 90.1695 25.4896C89.3039 26.3875 88.2582 27.0936 87.0325 27.608C85.8345 28.0944 84.5326 28.342 83.1337 28.342C81.7349 28.342 80.4191 28.0979 79.1952 27.608C77.9971 27.0936 76.9515 26.3875 76.0581 25.4896C75.1925 24.5917 74.5052 23.5404 73.9945 22.3304C73.5115 21.0978 73.2656 19.75 73.2656 18.2855C73.2656 16.821 73.508 15.475 73.9945 14.2423C74.5052 13.0097 75.1925 11.9584 76.0581 11.0831C76.9497 10.1852 77.9937 9.49131 79.1952 9.00488C80.4191 8.49055 81.7314 8.23425 83.1337 8.23425ZM83.1337 23.6729C83.7449 23.6729 84.3439 23.5578 84.9325 23.3242C85.5194 23.0906 86.0301 22.7593 86.4629 22.3234C86.8957 21.8614 87.242 21.2965 87.4965 20.6305C87.7787 19.961 87.9155 19.1816 87.9155 18.282C87.9155 17.3824 87.7752 16.5995 87.4965 15.9335C87.242 15.264 86.8957 14.7131 86.4629 14.2789C86.0301 13.8169 85.5194 13.4717 84.9325 13.2381C84.3456 13.0079 83.7483 12.8894 83.1337 12.8894C82.5191 12.8894 81.9236 13.0045 81.335 13.2381C80.7481 13.4682 80.2374 13.8152 79.8046 14.2789C79.3717 14.7148 79.0134 15.2692 78.7346 15.9335C78.4801 16.603 78.352 17.3824 78.352 18.282C78.352 19.1816 78.4801 19.9645 78.7346 20.6305C79.0168 21.3 79.3717 21.8631 79.8046 22.3234C80.2374 22.7593 80.7464 23.094 81.335 23.3242C81.9219 23.5543 82.5191 23.6729 83.1337 23.6729Z"
          fill="#003264"
        />
        <path d="M71.1548 0H66.0684V27.875H71.1548V0Z" fill="#003264" />
        <path
          d="M54.1027 8.23425C55.505 8.23425 56.8035 8.49055 58.0015 9.00488C59.2255 9.49131 60.2694 10.1835 61.1385 11.0831C62.0301 11.9549 62.7191 13.0079 63.2022 14.2423C63.7129 15.475 63.9674 16.8227 63.9674 18.2855C63.9674 19.7483 63.7129 21.096 63.2022 22.3304C62.7191 23.5369 62.0318 24.59 61.1385 25.4896C60.2729 26.3875 59.2255 27.0936 58.0015 27.608C56.8035 28.0944 55.5016 28.342 54.1027 28.342C52.7039 28.342 51.3881 28.0979 50.1641 27.608C48.9661 27.0936 47.9204 26.3875 47.0271 25.4896C46.1615 24.5917 45.4742 23.5404 44.9634 22.3304C44.4804 21.0978 44.2363 19.75 44.2363 18.2855C44.2363 16.821 44.477 15.475 44.9634 14.2423C45.4742 13.0097 46.1615 11.9584 47.0271 11.0831C47.9187 10.1852 48.9626 9.49131 50.1641 9.00488C51.3881 8.49055 52.7004 8.23425 54.1027 8.23425ZM54.1027 23.6729C54.7138 23.6729 55.3129 23.5578 55.9015 23.3242C56.4884 23.094 56.9991 22.7593 57.4319 22.3234C57.8647 21.8614 58.2092 21.2965 58.4655 20.6305C58.7477 19.961 58.8844 19.1816 58.8844 18.282C58.8844 17.3824 58.7442 16.5995 58.4655 15.9335C58.211 15.264 57.8647 14.7131 57.4319 14.2789C56.9991 13.8169 56.4884 13.4717 55.9015 13.2381C55.3146 13.0079 54.7173 12.8894 54.1027 12.8894C53.4881 12.8894 52.8908 13.0045 52.3039 13.2381C51.7171 13.4682 51.2063 13.8152 50.7735 14.2789C50.3407 14.7148 49.9823 15.2692 49.7036 15.9335C49.4491 16.603 49.321 17.3824 49.321 18.282C49.321 19.1816 49.4491 19.9645 49.7036 20.6305C49.9858 21.3 50.3407 21.8631 50.7735 22.3234C51.2063 22.7593 51.7171 23.094 52.3039 23.3242C52.8908 23.5543 53.4881 23.6729 54.1027 23.6729Z"
          fill="#003264"
        />
        <path
          d="M38.4995 8.61732H42.284V13.1609H38.4995V21.1553C38.4995 21.9765 38.6778 22.5536 39.0362 22.8884C39.4188 23.1952 39.9797 23.3504 40.7172 23.3504C41.0219 23.3504 41.3284 23.3365 41.6365 23.3138C41.9447 23.2911 42.1594 23.2493 42.2875 23.1987V27.4354C42.0589 27.5365 41.7006 27.6411 41.2176 27.7423C40.7588 27.8434 40.1615 27.8974 39.4188 27.8974C37.5837 27.8974 36.1312 27.3831 35.0612 26.3562C33.9913 25.3293 33.4546 23.8926 33.4546 22.0445V13.1661H30.0527V8.62255H31.0084C32.0021 8.62255 32.7292 8.33836 33.1898 7.77521C33.6728 7.18417 33.9169 6.52911 33.9169 5.68177V3.02643H38.5064V8.62429L38.4995 8.61732Z"
          fill="#003264"
        />
        <path d="M22.6758 27.8577V8.6236H27.7585V27.8594H22.6758V27.8577Z" fill="#003264" />
        <path
          d="M13.6975 27.8717H8.16397L0.722656 8.6236H6.39528L13.6785 27.8769L13.6993 27.8734V27.8699L13.6975 27.8717Z"
          fill="#003264"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9051 12.3197L16.3084 21.3809L12.7118 12.3197V12.3144C12.0077 10.4624 12.1392 9.13487 13.1702 8.72197C15.0196 8.09303 16.2531 10.675 16.3084 10.9503C16.3638 10.675 17.5973 8.09303 19.4466 8.72197C20.4777 9.13487 20.6109 10.4624 19.9085 12.3144V12.3197H19.9051Z"
          fill="#003264"
        />
      </svg>
    </SvgIcon>
  );
}
