import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

type MuiInputBaseComponentProps = {
  defaultProps?: ComponentsProps['MuiInputBase'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputBase'];
  variants?: ComponentsVariants['MuiInputBase'];
};

export const muiInputBaseComponent: MuiInputBaseComponentProps = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: theme.fontFamily.roboto,
      fontSize: '0.8125rem',
      lineHeight: '1.1875rem',
    }),
    input: ({ theme }) => ({
      'color': theme.palette.blue.blue_1,
      'height': '1.25rem',
      'padding': '0 0 0.5rem',

      '&::placeholder': {
        color: theme.palette.blue.blue_4,
      },
    }),
    hiddenLabel: {
      paddingTop: 0,
    },
    multiline: {
      padding: 0,
    },
  },
};
