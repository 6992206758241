import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

type MuiOutlinedInputComponentProps = {
  defaultProps?: ComponentsProps['MuiOutlinedInput'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiOutlinedInput'];
  variants?: ComponentsVariants['MuiOutlinedInput'];
};

export const muiOutlinedInputComponent: MuiOutlinedInputComponentProps = {
  styleOverrides: {
    input: {
      padding: '0.75rem',
    },
  },
};
