import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
  inputClasses,
} from '@mui/material';

type MuiInputLabelComponentProps = {
  defaultProps?: ComponentsProps['MuiInputLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputLabel'];
  variants?: ComponentsVariants['MuiInputLabel'];
};

export const muiInputLabelComponent: MuiInputLabelComponentProps = {
  styleOverrides: {
    root: {
      letterSpacing: 'normal',
      maxWidth: '100%',
      position: 'relative',
      transform: 'none',

      [`& + .${inputClasses.root}`]: {
        marginTop: '0.5rem',
      },
    },
  },
};
