import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

type MuiContainerComponentProps = {
  defaultProps?: ComponentsProps['MuiContainer'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiContainer'];
  variants?: ComponentsVariants['MuiContainer'];
};

export const muiContainerComponent: MuiContainerComponentProps = {
  styleOverrides: {
    root: ({ theme }) => ({
      paddingRight: '1.25em',
      paddingLeft: '1.25em',

      [`${theme.breakpoints.up('sm')}`]: {
        paddingRight: '1.25em',
        paddingLeft: '1.25em',
      },

      [`${theme.breakpoints.up('lg')}`]: {
        maxWidth: 'none',
      },
    }),
  },
};
