import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { defaultTheme, vitoloTheme } from '@dentolo/common-components';

import { useVertical, VERTICAL } from '@dentolo/common-utils';
import locale from './locale';

import { VITE_ROOT_DIV_DATA_CHANGE_CONFIRMATION } from './app/environment';
import App from './app/App';

const defaultLocale = 'de';

const rootElement = document.getElementById(VITE_ROOT_DIV_DATA_CHANGE_CONFIRMATION);

function getThemeToUse(vertical: VERTICAL) {
  switch (vertical) {
    case VERTICAL.DENTOLO:
      return defaultTheme;
    case VERTICAL.PETOLO:
      return defaultTheme;
    case VERTICAL.VITOLO:
      return vitoloTheme;
    default:
      return defaultTheme;
  }
}

export default function DataChangeConfirmation() {
  const vertical = useVertical();
  const themeToUse = getThemeToUse(vertical);

  if (rootElement) {
    if (!rootElement.shadowRoot) {
      rootElement.attachShadow({ mode: 'open' });
    }

    const normalizeCss = `:host { all: initial; }`;

    const styleTag = document.createElement('style');
    styleTag.innerHTML = normalizeCss;

    if (rootElement.shadowRoot) {
      rootElement.shadowRoot.appendChild(styleTag);
    }

    const cache = createCache({
      key: 'dentolo',
      container: rootElement.shadowRoot || rootElement,
    });

    return (
      <React.StrictMode>
        <IntlProvider locale={defaultLocale} messages={locale[`${defaultLocale}`]}>
          <CacheProvider value={cache}>
            <ThemeProvider theme={themeToUse}>
              <CssBaseline />

              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ThemeProvider>
          </CacheProvider>
        </IntlProvider>
      </React.StrictMode>
    );
  }
}
