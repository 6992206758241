import { useEffect, useState } from 'react';
import { VITE_API_BASE_URL, VITE_API_KEY } from '../app/environment';
import { fetchWithErrorHandling, jsonHeaders } from '../utils/utils';

const baseUrl = VITE_API_BASE_URL;
const apiKey = VITE_API_KEY;

export interface CustomerDataUpdateRequest {
  in?: string | null;
  requested_changes?: {
    old_value?: string | null;
    updated_value?: string | null;
  } | null;
}

export interface CustomerDataUpdateRequestResponse {
  customer_data_changes?: CustomerDataUpdateRequest[] | null;
}

export const fetchCustomerDataUpdateRequest = (requestId: string) => {
  const urlToUse = `${baseUrl}/api/v1/customer_update_requests/${requestId || ''}`;

  return fetchWithErrorHandling<CustomerDataUpdateRequestResponse>(urlToUse, {
    headers: jsonHeaders(apiKey),
    method: 'GET',
  });
};

export default function useCustomerDataUpdateRequest(requestId?: string) {
  const [requestLoading, setRequestLoading] = useState(true);
  const [dataUpdateRequests, setDataUpdateRequests] = useState<CustomerDataUpdateRequest[]>([]);

  useEffect(() => {
    if (requestId) {
      setRequestLoading(true);

      fetchCustomerDataUpdateRequest(requestId)
        .then((response) => {
          if (
            response &&
            'customer_data_changes' in response &&
            Array.isArray(response.customer_data_changes)
          ) {
            setDataUpdateRequests(response.customer_data_changes);
          }
        })
        .finally(() => setRequestLoading(false));
    } else {
      setDataUpdateRequests([]);
      setRequestLoading(false);
    }
  }, [requestId]);

  return { requestLoading, dataUpdateRequests };
}
