import React from 'react';

import HeaderLogo from './HeaderLogo';
import {
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderSticky,
} from './ApplicationHeader.styled';

export default function HeaderWithoutActions() {
  return (
    <StyledHeader>
      <StyledHeaderSticky>
        <StyledHeaderContainer>
          <HeaderLogo />
        </StyledHeaderContainer>
      </StyledHeaderSticky>
    </StyledHeader>
  );
}
