import { createTheme } from '@mui/material/styles';
import { boxShadow, colors, fontfamily } from './palette';
import {
  muiButtonComponent,
  muiContainerComponent,
  muiInputBaseComponent,
  muiInputComponent,
  muiInputLabelComponent,
  muiOutlinedInputComponent,
  muiTypographyComponent,
  muiTypographyOptions,
} from './overrides';

interface BlueColors {
  blue_1: string;
  blue_2: string;
  blue_2_1: string;
  blue_3: string;
  blue_4: string;
  blue_5: string;
}

interface GreenColors {
  green_1: string;
  green_2: string;
  green_3: string;
  green_4: string;
}

interface GrayColors {
  gray_1: string;
  gray_2: string;
  gray_3: string;
  gray_4: string;
  gray_5: string;
  gray_6: string;
}

interface NeutralColors {
  black: string;
  white: string;
}

interface DarkBlueColors {
  dark_blue_1: string;
  dark_blue_2: string;
}

interface RedColors {
  red_1: string;
  red_2: string;
  red_3: string;
}

interface YellowColors {
  yellow_1: string;
  yellow_2: string;
}
interface OrangeColors {
  orange_1: string;
}
interface VioletColors {
  violet_1: string;
  violet_2: string;
  violet_3: string;
}
interface CustomPalette {
  blue: BlueColors;
  green: GreenColors;
  gray: GrayColors;
  neutral: NeutralColors;
  darkBlue: DarkBlueColors;
  red: RedColors;
  yellow: YellowColors;
  orange: OrangeColors;
  violet: VioletColors;
}

interface CustomPaletteOptions {
  blue?: BlueColors;
  green?: GreenColors;
  gray?: GrayColors;
  neutral?: NeutralColors;
  darkBlue?: DarkBlueColors;
  red?: RedColors;
  yellow?: YellowColors;
  orange?: OrangeColors;
  violet?: VioletColors;
}

interface CustomTheme {
  boxShadow: {
    navigation_shadow: string;
    dropdown_shadow: string;
    focus_shadow_green_1: string;
    focus_shadow_violet_3: string;
    header_shadow: string;
  };

  fontFamily: {
    circular_tt: string;
    roboto: string;
  };
}

interface CustomThemeOptions {
  boxShadow?: {
    navigation_shadow?: string;
    dropdown_shadow?: string;
    focus_shadow_green_1?: string;
    focus_shadow_violet_3?: string;
    header_shadow?: string;
  };

  fontFamily?: {
    circular_tt?: string;
    roboto?: string;
  };
}

declare module '@mui/material/styles' {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPaletteOptions {}
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomThemeOptions {}
}

const defaultTheme = createTheme({
  palette: {
    common: {
      black: colors.black,
      white: colors.white,
    },
    error: {
      main: colors.red_2,
      contrastText: colors.white,
    },
    info: {
      main: colors.blue_3,
      contrastText: colors.white,
    },
    primary: {
      main: colors.blue_1,
      contrastText: colors.green_1,
    },
    secondary: {
      main: colors.green_1,
      contrastText: colors.blue_1,
    },
    text: {
      primary: colors.blue_1,
    },
    success: {
      main: colors.green_3,
      contrastText: colors.white,
    },
    warning: {
      main: colors.yellow_1,
      contrastText: colors.black,
    },
    grey: {
      '50': colors.gradient_1,
      '100': colors.gradient_2,
    },
    blue: {
      blue_1: colors.blue_1,
      blue_2: colors.blue_2,
      blue_2_1: colors.blue_2_1,
      blue_3: colors.blue_3,
      blue_4: colors.blue_4,
      blue_5: colors.blue_5,
    },
    green: {
      green_1: colors.green_1,
      green_2: colors.green_2,
      green_3: colors.green_3,
      green_4: colors.green_4,
    },
    gray: {
      gray_1: colors.gray_1,
      gray_2: colors.gray_2,
      gray_3: colors.gray_3,
      gray_4: colors.gray_4,
      gray_5: colors.gray_5,
      gray_6: colors.gray_6,
    },
    neutral: {
      black: colors.black,
      white: colors.white,
    },
    darkBlue: {
      dark_blue_1: colors.dark_blue_1,
      dark_blue_2: colors.dark_blue_2,
    },
    red: {
      red_1: colors.red_1,
      red_2: colors.red_2,
      red_3: colors.red_3,
    },
    yellow: {
      yellow_1: colors.yellow_1,
      yellow_2: colors.yellow_2,
    },
    orange: {
      orange_1: colors.orange_1,
    },
    violet: {
      violet_1: colors.violet_1,
      violet_2: colors.violet_2,
      violet_3: colors.violet_3,
    },
  },
  shadows: [
    'none',
    boxShadow.focus_shadow_green_1,
    boxShadow.navigation_shadow,
    boxShadow.dropdown_shadow,
    boxShadow.header_shadow,
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  // All the different variants available for the Typography component
  typography: muiTypographyOptions,
  // Expose the box shadows through the theme
  boxShadow: {
    navigation_shadow: boxShadow.navigation_shadow,
    dropdown_shadow: boxShadow.dropdown_shadow,
    focus_shadow_green_1: boxShadow.focus_shadow_green_1,
    focus_shadow_violet_3: boxShadow.focus_shadow_violet_3,
    header_shadow: boxShadow.header_shadow,
  },
  // Expose the font families through the theme
  fontFamily: {
    circular_tt: fontfamily.circular_tt,
    roboto: fontfamily.roboto,
  },
  // Override properties for available components
  components: {
    MuiButton: muiButtonComponent,
    MuiContainer: muiContainerComponent,
    MuiInput: muiInputComponent,
    MuiInputBase: muiInputBaseComponent,
    MuiInputLabel: muiInputLabelComponent,
    MuiOutlinedInput: muiOutlinedInputComponent,
    MuiTypography: muiTypographyComponent,
  },
});

export default defaultTheme;
