// This should not be exposed through the package exports.
// Colours are meant to be used through the theme.

export const colors = {
  /* -- blue -- */
  blue_1: '#003264', // Navigation BG/ Headers/ Primary button
  blue_2: '#DFF1FD', // Menu navigation texts/ Menu navigation icons/ BGs
  blue_2_1: '#F5FBFE', // Backgrounds
  blue_3: '#03A9F4', // Control button icons/
  blue_4: '#A5ADD8', // Panel border/ Placeholder texts/ Budget graphs/ Banner background
  blue_5: '#1E88E5', // Links/ Policy type name/
  /* -- ---- -- */

  /* -- green -- */
  green_1: '#32FF96', // Primary button label
  green_2: '#26A69A', // Edit customer button/ SEPA status
  green_3: '#4CAF50', // Success/ confirmation banner
  green_4: '#7CFAA0', // Badges/ Statuses/ Budget graphs/ Panel border
  /* -- ----- -- */

  /* -- gray -- */
  gray_1: '#999999', // Form fields (lines)/
  gray_2: '#D9D9D9', // Divider/
  gray_3: '#E7E7E8', // Background
  gray_4: '#EEEDED', // Table row background
  gray_5: '#F5F5F5', // "More" button when editing
  gray_6: '#777', // status gray color
  /* -- ---- -- */

  /* -- neutral -- */
  black: '#333333', // Control arrows/ Body texts (p)/
  white: '#FFFFFF', // Background color for panels
  /* -- ------- -- */

  /* -- darkBlue -- */
  dark_blue_1: '#455A64', // Pagination navigation controls
  dark_blue_2: '#607D8B', // Export buttons
  /* -- -------- -- */

  /* -- red -- */
  red_1: '#F44336', // Notifications BG chips
  red_2: '#FF5722', // Error banner
  red_3: '#FDEEED', // Error/Alert messages background
  /* -- --- -- */

  /* -- yellow -- */
  yellow_1: '#FBE858', // Notification banners
  yellow_2: '#F9F6DA', // Alert/Information messages banners

  /* -- Orange -- */
  orange_1: '#FF9800',
  /* -- ------ -- */

  /* -- Violet -- */
  violet_1: '#9C27B0',
  violet_2: '#CBB7FF',
  violet_3: '#7747F4',
  /* -- ------ -- */

  /* -- Gradient -- */
  gradient_1: 'rgba(0, 0, 0, 0.2)',
  gradient_2: 'rgba(0, 50, 100, 0.15)',
};

export const fontfamily = {
  circular_tt: '"Circular TT", "Arial", sans-serif',
  roboto: '"Roboto", Helvetica Neue, Helvetica, Arial, sans-serif',
};

export const boxShadow = {
  navigation_shadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  dropdown_shadow: '2px 4px 12px 0px rgba(0, 0, 0, 0.25)',
  focus_shadow_green_1: '0px 0px 8px 0px rgba(50, 255, 150, 0.50);',
  focus_shadow_violet_3: '0px 0px 15px 0px rgba(119, 71, 244, 1);',
  header_shadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.10)',
};
